import { isNil } from '../utils'

interface I_CacheService {
  set<T>(key: string, value: T): void
  get<T>(key: string, defaultValue: T): T
  get<T>(key: string): T | null
  delete(key: string): void
  clear(): void
}

const prefixedKey = (key: string) => {
  return `mg-v1-${key}`
}

export const cacheService: I_CacheService = {
  set: (key: string, value: any) => {
    localStorage.setItem(prefixedKey(key), JSON.stringify(value))
  },
  get(key: string, defaultValue: any = null) {
    const value = localStorage.getItem(prefixedKey(key))
    if (isNil(value)) return defaultValue

    return JSON.parse(value)
  },
  delete: (key: string) => {
    localStorage.removeItem(prefixedKey(key))
  },
  clear: () => {
    localStorage.clear()
  },
}
