import axios from 'axios'
import { envConfig } from '../../configs'

export const api = axios.create({
  baseURL: envConfig.API_URL,
})

export const setToken = (token: string | null) => {
  if (token) {
    api.defaults.headers.common.Authorization = `Bearer ${token}`
  } else {
    delete api.defaults.headers.common.Authorization
  }
}

api.interceptors.request.use((config) => {
  const token = ''

  if (token && config.headers) {
    config.headers.Authorization = `Bearer ${token}`
  }

  return config
})
