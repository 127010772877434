import { Button, Col, Form, Input, Modal, notification, Row, Space, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import React from 'react'
import { ElementOfDocumentTemplateModel, ElementsOfDocumentTemplateList } from '@mg/contracts'
import { css } from '@emotion/react'
import { isArray, parseError } from '../../utils'
import { documentTemplatesService } from '../../services/api'
import { CustomButton } from '../../components/CustomButton'

export const ElementsOfDocumentTemplate: React.FC = () => {
  const [notificationApi, contextHolder] = notification.useNotification({ top: 60 })
  const [tableQuery, setTableQuery] = React.useState<{
    page: number
    pageSize: number
    order: 'asc' | 'desc'
  }>({ page: 1, pageSize: 25, order: 'desc' })

  const [isLoading, setIsLoading] = React.useState(false)
  const [data, setData] = React.useState<ElementsOfDocumentTemplateList.Result>({
    elements: [],
    count: 0,
  })

  const loadData = React.useCallback(() => {
    setIsLoading(true)
    documentTemplatesService
      .listElements({
        page: tableQuery.page,
        take: tableQuery.pageSize,
        order: tableQuery.order,
      })
      .then((res) => setData(res))
      .catch((error) => {
        notificationApi.error({
          message: 'Error',
          description: parseError(error),
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [notificationApi, tableQuery.order, tableQuery.page, tableQuery.pageSize])

  React.useEffect(() => {
    loadData()
  }, [loadData])

  // Create or update modal
  const [updateModalOpen, setUpdateModalOpen] = React.useState<ElementOfDocumentTemplateModel>()
  const [isUpdateLoading, setIsUpdateLoading] = React.useState(false)
  const [updateForm] = Form.useForm()
  const onCancelModal = React.useCallback(() => {
    setUpdateModalOpen(undefined)
    setIsUpdateLoading(false)
    updateForm.resetFields()
  }, [updateForm])
  const onFinishUpdate = React.useCallback(
    (values: {
      id: number
      name: string
      description: string
      order: number
      documentTemplateId: number
    }) => {
      if (!updateModalOpen) return
      setIsUpdateLoading(true)

      if (updateModalOpen.id === -1) {
        documentTemplatesService
          .createElement(values)
          .then((res) => {
            // console.log(res)
            const newData = {
              count: data.count + 1,
              elements: [res.element, ...data.elements],
            }
            setData(newData)
            // loadData()
          })
          .catch((error) => {
            notificationApi.error({
              message: 'Error',
              description: parseError(error),
            })
          })
          .finally(() => {
            setIsUpdateLoading(false)
            onCancelModal()
          })
      } else {
        documentTemplatesService
          .updateElement(updateModalOpen.id, values)
          .then(() => {
            loadData()
            onCancelModal()
          })
          .catch((error) => {
            notificationApi.error({
              message: 'Error',
              description: parseError(error),
            })
          })
          .finally(() => {
            setIsUpdateLoading(false)
            onCancelModal()
          })
      }
    },
    [data.count, data.elements, loadData, notificationApi, onCancelModal, updateModalOpen],
  )

  const columns: ColumnsType<ElementOfDocumentTemplateModel> = React.useMemo(() => {
    return [
      {
        title: 'ID',
        dataIndex: 'id',
        fixed: 'left',
      },
      {
        title: 'TypeID',
        dataIndex: 'documentTemplateId',
        sortOrder: tableQuery.order === 'desc' ? 'descend' : 'ascend',
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
        render: (documentTemplateId) => documentTemplateId,
      },
      {
        title: 'Order',
        dataIndex: 'order',
        render: (order) => order,
      },
      {
        title: 'Name',
        dataIndex: 'name',
        render: (name: string) => {
          if (name === 'request_new_requirement') {
            return 'What documents would you like to have in feature iterations?'
          }

          return name
        },
      },
      {
        title: 'Description',
        dataIndex: 'description',
        render: (description: string) => {
          return (
            <div
              css={css`
                white-space: pre-wrap;
              `}
            >
              {description}
            </div>
          )
        },
      },
      {
        key: 'actions',
        fixed: 'right',
        render: (_, record) => (
          <CustomButton
            size="small"
            type="primary"
            ghost
            onClick={() => {
              setUpdateModalOpen(record)
              updateForm.setFieldsValue(record)
            }}
          >
            Edit
          </CustomButton>
        ),
      },
    ]
  }, [tableQuery.order, updateForm])

  return (
    <>
      {contextHolder}
      <Modal
        centered
        width={1200}
        title={updateModalOpen?.id === -1 ? 'Create New Element' : 'Update Element'}
        open={!!updateModalOpen}
        confirmLoading={isUpdateLoading}
        cancelButtonProps={{
          type: 'primary',
          ghost: true,
          size: 'small',
        }}
        okButtonProps={{
          size: 'small',
        }}
        okText={updateModalOpen?.id === -1 ? 'Create' : 'Save'}
        cancelText="Cancel"
        onOk={updateForm?.submit}
        onCancel={onCancelModal}
      >
        <Form name="document-type" layout="vertical" form={updateForm} onFinish={onFinishUpdate}>
          <Row gutter={16}>
            <Col span={2}>
              <Form.Item name="id" label="ID">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item name="documentTemplateId" label="TypeID">
                <Input />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item name="order" label="Order">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item name="name" label="Element Name">
            <Input />
          </Form.Item>
          <Form.Item name="description" label="Description">
            <Input.TextArea />
          </Form.Item>
          {updateModalOpen?.id !== -1 && (
            <Button
              type="primary"
              size="small"
              style={{ textAlign: 'center', background: 'red', borderColor: 'red' }}
              onClick={() => {
                documentTemplatesService
                  .deleteElement(updateModalOpen?.id || 0)
                  .then(() => {
                    loadData()
                    onCancelModal()
                    notificationApi.success({
                      message: 'Success',
                      description: `${updateModalOpen?.name} has been deleted`,
                    })
                  })
                  .catch((error) => {
                    notificationApi.error({
                      message: 'Error',
                      description: parseError(error),
                    })
                  })
                  .finally(() => {
                    setIsUpdateLoading(false)
                    onCancelModal()
                  })
              }}
            >
              Delete
            </Button>
          )}
        </Form>
      </Modal>

      <Space
        css={css({ textAlign: 'right' })}
        direction="vertical"
        style={{ width: '100%' }}
        size="large"
      >
        <Button
          type="primary"
          size="small"
          style={{ textAlign: 'center' }}
          onClick={() => {
            const newType: ElementOfDocumentTemplateModel = {
              id: -1,
              name: '',
              description: '',
              order: 0,
              documentTemplateId: 0,
            }
            setUpdateModalOpen(newType)
            updateForm.setFieldsValue(newType)
          }}
        >
          Create New Element
        </Button>
      </Space>
      <Table
        size="small"
        loading={isLoading}
        pagination={{
          position: ['bottomRight'],
          total: data.count,
          current: tableQuery.page,
          showSizeChanger: true,
          showTotal: (total) => `Total ${total} items`,
          pageSizeOptions: ['25', '50', '100'],
          pageSize: tableQuery.pageSize,
        }}
        onChange={(pagination, filters, sorter) => {
          setTableQuery((prev) => {
            let { order } = prev

            if (sorter && !isArray(sorter) && sorter.field === 'documentTemplateId') {
              order = sorter.order === 'descend' ? 'desc' : 'asc'
            }

            return {
              page: pagination.current || prev.page,
              pageSize: pagination.pageSize || prev.pageSize,
              order,
            }
          })
        }}
        columns={columns}
        dataSource={data.elements}
        rowKey="id"
        scroll={{ x: true }}
        tableLayout="fixed"
      />
    </>
  )
}
