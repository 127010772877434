export const privateRoutes = {
  userAccounts: {
    path: '/user-accounts',
  },
  documentTemplates: {
    path: '/document-templates',
  },
  elementsOfDocumentTemplate: {
    path: '/elements-of-document-templates',
  },
  questionaries: {
    path: '/questionaries',
  },
}
