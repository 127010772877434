import { observer } from 'mobx-react-lite'
import { Col, Image, Layout, Menu, notification, Row, theme, ThemeConfig } from 'antd'
import { css } from '@emotion/react'
import React, { useMemo } from 'react'
import { ItemType } from 'antd/es/menu/hooks/useItems'
import { autorun } from 'mobx'
import { useMatches } from 'react-router-dom'
import { OauthTypes } from '@mg/contracts'
import { ButtonLink, MenuLink } from '../Link'
import { privateRoutes } from '../../navigation/private'
import Logo from '../../assets/full-logo.svg'
import { identityStore } from '../../providers/store'
import { ThemeConfigProvider } from '../../providers/theme'
import { authService } from '../../services/api'
import { usePopupWindow } from '../../hooks/usePopupWindow'
import { publicRoutes } from '../../navigation/public'
import { parseError } from '../../utils'

const _PageHeader = () => {
  const { token } = theme.useToken()
  const [notificationApi, contextHolder] = notification.useNotification({
    top: 60,
  })
  const headerTheme: ThemeConfig = useMemo(() => {
    return {
      components: {
        Layout: {
          headerBg: '#000000',
          colorText: token.colorTextLightSolid,
        },
        Menu: {
          itemBg: '#000000',
          menuSubMenuBg: '#000000',
        },
      },
    }
  }, [token.colorTextLightSolid])

  const [menuItems, setMenuItems] = React.useState<ItemType[]>([])

  const openPopup = usePopupWindow('oauth')

  const handleOauth = React.useCallback(() => {
    const redirectUrl = `${window.location.origin}${publicRoutes.oauth.path}`
    authService
      .getOauthUrl({ type: OauthTypes.GOOGLE, redirectUrl })
      .then(({ url }) => {
        openPopup({
          url,
          height: 600,
          onSuccess: ({ code }) => {
            if (code) {
              identityStore.login({ code, redirectUrl }).catch((error) => {
                notificationApi.error({
                  message: 'Error',
                  description: parseError(error),
                })
              })
            }
          },
        })
      })
      .catch((error) => {
        notificationApi.error({ message: 'Error', description: parseError(error) })
      })
  }, [notificationApi, openPopup])

  React.useEffect(() => {
    const dispose = autorun(() => {
      if (identityStore.isLoggedIn) {
        setMenuItems([
          {
            label: 'Logout',
            key: 'logout',
            onClick: identityStore.logout,
          },
        ])
      } else {
        setMenuItems([
          {
            label: 'Login',
            key: 'login',
            onClick: handleOauth,
          },
        ])
      }
    })

    return () => {
      dispose()
    }
  }, [handleOauth])

  const matches = useMatches()
  const selectedKeys = matches.map((match) => match.id)

  return (
    <ThemeConfigProvider theme={headerTheme}>
      {contextHolder}
      <Layout.Header
        css={css`
          padding-inline: 0;
        `}
      >
        <Row
          justify="space-between"
          css={css`
            padding-inline: 16px;
          `}
        >
          <Col flex="auto">
            {identityStore.isLoggedIn ? (
              <Menu
                theme="dark"
                mode="horizontal"
                selectedKeys={selectedKeys}
                items={[
                  {
                    label: <MenuLink to={privateRoutes.userAccounts.path}>Accounts</MenuLink>,
                    key: 'accounts',
                  },
                  {
                    label: (
                      <MenuLink to={privateRoutes.documentTemplates.path}>Document Types</MenuLink>
                    ),
                    key: 'document_templates',
                  },
                  {
                    label: (
                      <MenuLink to={privateRoutes.elementsOfDocumentTemplate.path}>
                        Elements of Templates
                      </MenuLink>
                    ),
                    key: 'elements_of_document_templates',
                  },
                  {
                    label: <MenuLink to={privateRoutes.questionaries.path}>Questionaries</MenuLink>,
                    key: 'questionaries',
                  },
                ]}
              />
            ) : (
              <ButtonLink to={privateRoutes.userAccounts.path} type="text">
                <Image src={Logo} width={110} preview={false} alt="MindyGem Logo" />
              </ButtonLink>
            )}
          </Col>
          <Col flex="auto">
            <Menu
              theme="dark"
              mode="horizontal"
              selectedKeys={[]}
              css={css`
                justify-content: flex-end;
              `}
              items={menuItems}
            />
          </Col>
        </Row>
      </Layout.Header>
    </ThemeConfigProvider>
  )
}

export const PageHeader = observer(_PageHeader)
