import {
  DocumentTemplatesList,
  DocumentTemplateInput,
  ElementsOfDocumentTemplateList,
  ElementOfDocumentTemplateInput,
} from '@mg/contracts'
import qs from 'qs'
import { api } from './api'

export const documentTemplatesService = {
  async listDocumentTemplates(query?: DocumentTemplatesList.Query) {
    let url = '/admin/document-templates'

    if (query) {
      url = `${url}?${qs.stringify(query)}`
    }

    const res = await api.get<DocumentTemplatesList.Result>(url)

    return res.data
  },

  async createDocumentTemplates(body: DocumentTemplateInput.CreateBody) {
    const res = await api.post<DocumentTemplateInput.Result>(
      '/admin/document-templates/create',
      body,
    )

    return res.data
  },

  async updateDocumentTemplates(
    documentTemplateId: number,
    body: DocumentTemplateInput.UpdateBody,
  ) {
    const res = await api.patch<DocumentTemplateInput.Result>(
      `/admin/document-templates/${documentTemplateId}`,
      body,
    )

    return res.data.documentTemplate
  },

  async deleteDocumentTemplates(documentTypeId: number) {
    const res = await api.delete<void>(`/admin/document-templates/delete/${documentTypeId}`)

    return res.data
  },

  // element of document types
  async listElements(query?: ElementsOfDocumentTemplateList.Query) {
    let url = '/admin/elements-of-document-templates'

    if (query) {
      url = `${url}?${qs.stringify(query)}`
    }

    const res = await api.get<ElementsOfDocumentTemplateList.Result>(url)

    return res.data
  },

  async createElement(body: ElementOfDocumentTemplateInput.CreateBody) {
    const res = await api.post<ElementOfDocumentTemplateInput.Result>(
      '/admin/elements-of-document-templates/create',
      body,
    )

    return res.data
  },

  async updateElement(elementId: number, body: ElementOfDocumentTemplateInput.UpdateBody) {
    const res = await api.patch<ElementOfDocumentTemplateInput.Result>(
      `/admin/elements-of-document-templates/${elementId}`,
      body,
    )

    return res.data.element
  },

  async deleteElement(elementId: number) {
    const res = await api.delete<void>(`/admin/elements-of-document-templates/delete/${elementId}`)

    return res.data
  },
}
