import { AdminUserInput, AdminUserList } from '@mg/contracts'
import qs from 'qs'
import { api } from './api'

export const userAccountService = {
  async list(query?: AdminUserList.Query) {
    let url = '/admin/accounts'

    if (query) {
      url = `${url}?${qs.stringify(query)}`
    }

    const res = await api.get<AdminUserList.Result>(url)

    return res.data
  },
  async update(params: AdminUserInput.Params, body: AdminUserInput.UpdateBody) {
    const res = await api.patch<AdminUserInput.Result>(`/admin/accounts/${params.id}`, body)

    return res.data.account
  },
}
