import React, { ComponentProps } from 'react'
import { Link, useHref, useLinkClickHandler } from 'react-router-dom'
import { CustomButton } from '../CustomButton'

type T_Props = Omit<ComponentProps<typeof CustomButton>, 'href'> & ComponentProps<typeof Link>

export const ButtonLink = React.forwardRef<HTMLAnchorElement, T_Props>(
  (
    {
      onClick,
      replace = false,
      state,
      target,
      to,
      reloadDocument,
      relative,
      preventScrollReset,
      ...rest
    }: T_Props,
    ref,
  ) => {
    const href = useHref(to, { relative })
    const internalOnClick = useLinkClickHandler(to, {
      replace,
      state,
      target,
      preventScrollReset,
      relative,
    })

    const handleClick = (
      event: React.MouseEvent<HTMLAnchorElement> & React.MouseEvent<HTMLButtonElement>,
    ) => {
      if (onClick) {
        onClick(event as React.MouseEvent<HTMLAnchorElement>)
      }

      if (!event.defaultPrevented) {
        internalOnClick(event as React.MouseEvent<HTMLAnchorElement>)
      }
    }

    return (
      <CustomButton
        {...rest}
        href={href}
        onClick={reloadDocument ? onClick : handleClick}
        ref={ref}
        target={target}
      />
    )
  },
)
