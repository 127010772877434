import {
  DocumentTypes,
  Domains,
  ElementTypes,
  PlatformType,
  T_DocumentType,
  T_Domain,
  T_ElementType,
  T_PlatformType,
} from './models'
import { T_EnumMap } from './types'

export const OauthTypes = {
  GOOGLE: 'google',
  LINKEDIN: 'linkedin',
} as const

export type T_OauthType = (typeof OauthTypes)[keyof typeof OauthTypes]

export const REGEX = {
  password: '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$',
}

export const ProjectTypeMap: T_EnumMap<T_PlatformType> = {
  [PlatformType.DESKTOP]: {
    label: 'Desktop',
    value: PlatformType.DESKTOP,
  },
  [PlatformType.IOS]: {
    label: 'iOS',
    value: PlatformType.IOS,
  },
  [PlatformType.MOBILE_MULTIPLATFORM]: {
    label: 'Mobile Multiplatform',
    value: PlatformType.MOBILE_MULTIPLATFORM,
  },
  [PlatformType.WEB]: {
    label: 'Web',
    value: PlatformType.WEB,
  },
  [PlatformType.ANDROID]: {
    label: 'Android',
    value: PlatformType.ANDROID,
  },
  [PlatformType.OTHERS]: {
    label: 'Others',
    value: PlatformType.OTHERS,
  },
} as const
export const ProjectDomainMap: T_EnumMap<T_Domain> = {
  [Domains.PRODUCTIVITY_AND_COLLABORATION]: {
    label: 'Productivity and Collaboration',
    value: Domains.PRODUCTIVITY_AND_COLLABORATION,
  },
  [Domains.SOCIAL_NETWORKING]: {
    label: 'Social Networking',
    value: Domains.SOCIAL_NETWORKING,
  },
  [Domains.ECOMMERCE_AND_RETAIL]: {
    label: 'E-commerce and Retail',
    value: Domains.ECOMMERCE_AND_RETAIL,
  },
  [Domains.ENTERTAINMENT_AND_MEDIA]: {
    label: 'Entertainment and Media',
    value: Domains.ENTERTAINMENT_AND_MEDIA,
  },
  [Domains.EDUCATION_AND_LEARNING]: {
    label: 'Education and Learning',
    value: Domains.EDUCATION_AND_LEARNING,
  },
  [Domains.HEALTH_AND_FITNESS]: {
    label: 'Health and Fitness',
    value: Domains.HEALTH_AND_FITNESS,
  },
  [Domains.TRAVEL_AND_TRANSPORTATION]: {
    label: 'Travel and Transportation',
    value: Domains.TRAVEL_AND_TRANSPORTATION,
  },
  [Domains.FINANCE_AND_BANKING]: {
    label: 'Finance and Banking',
    value: Domains.FINANCE_AND_BANKING,
  },
  [Domains.UTILITIES_AND_TOOLS]: {
    label: 'Utilities and Tools',
    value: Domains.UTILITIES_AND_TOOLS,
  },
  [Domains.GAMING]: {
    label: 'Gaming',
    value: Domains.GAMING,
  },
  [Domains.OTHERS]: {
    label: 'Others',
    value: Domains.OTHERS,
  },
} as const
export const ElementTypeMap: T_EnumMap<T_ElementType> = {
  [ElementTypes.UNKNOWN]: {
    label: '-',
    value: ElementTypes.UNKNOWN,
  },
  [ElementTypes.BUTTON]: {
    label: 'Button',
    value: ElementTypes.BUTTON,
  },
  [ElementTypes.TEXT_INPUT]: {
    label: 'Text Input',
    value: ElementTypes.TEXT_INPUT,
  },
  [ElementTypes.RADIO_BUTTON]: {
    label: 'Radio Button',
    value: ElementTypes.RADIO_BUTTON,
  },
  [ElementTypes.CHECK_BOX]: {
    label: 'Checkbox',
    value: ElementTypes.CHECK_BOX,
  },
  [ElementTypes.TEXT]: {
    label: 'Text',
    value: ElementTypes.TEXT,
  },
  [ElementTypes.IMAGE]: {
    label: 'Image',
    value: ElementTypes.IMAGE,
  },
  [ElementTypes.MENU]: {
    label: 'Menu',
    value: ElementTypes.MENU,
  },
  [ElementTypes.LINK]: {
    label: 'Link',
    value: ElementTypes.LINK,
  },
  [ElementTypes.TAB]: {
    label: 'Tab',
    value: ElementTypes.TAB,
  },
  [ElementTypes.PROGRESS_BAR]: {
    label: 'Progress Bar',
    value: ElementTypes.PROGRESS_BAR,
  },
  [ElementTypes.TOGGLE_BUTTON]: {
    label: 'Toggle Button',
    value: ElementTypes.TOGGLE_BUTTON,
  },
} as const

export const DocumentTypeMap: T_EnumMap<T_DocumentType> = {
  [DocumentTypes.MAIN_DOCUMENT]: {
    label: 'Main Document',
    value: DocumentTypes.MAIN_DOCUMENT,
  },
  [DocumentTypes.EPIC]: {
    label: 'Epic',
    value: DocumentTypes.EPIC,
  },
  [DocumentTypes.USER_STORY]: {
    label: 'User Story',
    value: DocumentTypes.USER_STORY,
  },
  [DocumentTypes.UI_SPEC]: {
    label: 'Tech Design Specifications',
    value: DocumentTypes.UI_SPEC,
  },
} as const
