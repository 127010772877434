import { Expose, Type } from 'class-transformer'
import { PublicAccountModel } from './user-account.model'
import { FileModel } from './file.model'
import { LabelModel } from './label.model'

export const DocumentTypes = {
  MAIN_DOCUMENT: 'MAIN_DOCUMENT',
  EPIC: 'EPIC',
  USER_STORY: 'USER_STORY',
  UI_SPEC: 'UI_SPEC',
} as const

export type T_DocumentType = (typeof DocumentTypes)[keyof typeof DocumentTypes]

export const DocStatuses = {
  OPEN: 'OPEN',
  IN_PROGRESS: 'IN_PROGRESS',
  NEEDS_REVIEW: 'NEEDS_REVIEW',
  ON_HOLD: 'ON_HOLD',
  COMPLETE: 'COMPLETE',
} as const
export type T_DocStatus = (typeof DocStatuses)[keyof typeof DocStatuses]

export class DocumentModel<T extends T_DocumentType = any> {
  @Expose()
  @Type(() => String)
  id!: string

  @Expose()
  @Type(() => Date)
  createdAt!: Date

  @Expose()
  @Type(() => Date)
  updatedAt!: Date

  @Expose()
  @Type(() => Number)
  version!: number

  @Expose()
  @Type(() => String)
  projectId!: string

  @Expose()
  @Type(() => String)
  name!: string

  @Expose()
  @Type(() => String)
  description!: string

  @Expose()
  @Type(() => String)
  type!: T

  @Expose()
  @Type(() => String)
  status!: T_DocStatus

  @Expose()
  @Type(() => Number)
  creatorId!: number | null

  @Expose()
  @Type(() => PublicAccountModel)
  updater!: PublicAccountModel | null

  @Expose()
  @Type(() => LabelModel)
  labels!: LabelModel[]

  @Expose()
  @Type(() => String)
  color?: string

  @Expose()
  @Type(() => String)
  templateType?: string
}

export class BaseDocumentModel<T extends T_DocumentType = any> {
  @Expose()
  @Type(() => String)
  docId!: string

  @Expose()
  @Type(() => DocumentModel<T>)
  doc!: DocumentModel<T>
}

export class ElementDocModel {
  @Expose()
  @Type(() => Number)
  displayId!: number

  @Expose()
  @Type(() => String)
  organizationId!: string

  @Expose()
  @Type(() => String)
  docId!: string

  @Expose()
  @Type(() => String)
  name!: string

  @Expose()
  @Type(() => String)
  description!: string

  @Expose()
  @Type(() => String)
  overview!: string

  @Expose()
  @Type(() => Number)
  order!: number
}

export class MainDocumentModel extends BaseDocumentModel {
  @Expose()
  @Type(() => ElementDocModel)
  elements!: ElementDocModel[]

  @Expose()
  @Type(() => String)
  templateType: string | undefined

  @Expose()
  @Type(() => String)
  prefix: string | undefined

  @Expose()
  @Type(() => String)
  color: string | undefined
}

export class AcceptanceCriteriaModel {
  @Expose()
  @Type(() => Number)
  displayId!: number

  @Expose()
  @Type(() => String)
  organizationId!: string

  @Expose()
  @Type(() => String)
  docId!: string

  @Expose()
  @Type(() => String)
  text!: string
}

export class UserStoryRefDoc {
  @Expose()
  @Type(() => String)
  id!: string

  @Expose()
  @Type(() => Date)
  createdAt!: Date

  @Expose()
  @Type(() => Date)
  updatedAt!: Date

  @Expose()
  @Type(() => Number)
  version!: number

  @Expose()
  @Type(() => String)
  projectId!: string

  @Expose()
  @Type(() => String)
  name!: string

  @Expose()
  @Type(() => String)
  description!: string

  @Expose()
  @Type(() => String)
  type!: typeof DocumentTypes.USER_STORY

  @Expose()
  @Type(() => String)
  status!: T_DocStatus

  @Expose()
  @Type(() => PublicAccountModel)
  updater!: PublicAccountModel | null
}

export class UserStoryRef {
  @Expose()
  @Type(() => String)
  docId!: string

  @Expose()
  @Type(() => UserStoryRefDoc)
  doc!: UserStoryRefDoc

  @Expose()
  @Type(() => Number)
  displayId!: number

  @Expose()
  @Type(() => String)
  organizationId!: string

  @Expose()
  @Type(() => String)
  epicId!: string

  @Expose()
  @Type(() => String)
  statement!: string
}

export class UserStoryDocumentModel extends BaseDocumentModel<typeof DocumentTypes.USER_STORY> {
  @Expose()
  @Type(() => Number)
  displayId!: number

  @Expose()
  @Type(() => String)
  organizationId!: string

  @Expose()
  @Type(() => String)
  epicId!: string

  @Expose()
  @Type(() => String)
  statement!: string

  @Expose()
  @Type(() => FileModel)
  attachments!: FileModel[]

  @Expose()
  @Type(() => UserStoryRef)
  dependencies!: UserStoryRef[]

  @Expose()
  @Type(() => AcceptanceCriteriaModel)
  acceptanceCriteria!: AcceptanceCriteriaModel[]
}

export class EpicDocumentModel extends BaseDocumentModel<typeof DocumentTypes.EPIC> {
  @Expose()
  @Type(() => Number)
  displayId!: number

  @Expose()
  @Type(() => String)
  organizationId!: string

  @Expose()
  @Type(() => UserStoryRef)
  stories!: UserStoryRef[]

  @Expose()
  @Type(() => FileModel)
  attachments!: FileModel[]
}

export class UiElementSpecModel {
  @Expose()
  @Type(() => Number)
  displayId!: number

  @Expose()
  @Type(() => String)
  organizationId!: string

  @Expose()
  @Type(() => Number)
  elementId!: number

  @Expose()
  @Type(() => String)
  text!: string
}

export const ElementTypes = {
  UNKNOWN: 'UNKNOWN',
  BUTTON: 'BUTTON',
  TEXT_INPUT: 'TEXT_INPUT',
  RADIO_BUTTON: 'RADIO_BUTTON',
  CHECK_BOX: 'CHECK_BOX',
  TEXT: 'TEXT',
  IMAGE: 'IMAGE',
  MENU: 'MENU',
  LINK: 'LINK',
  TAB: 'TAB',
  PROGRESS_BAR: 'PROGRESS_BAR',
  TOGGLE_BUTTON: 'TOGGLE_BUTTON',
} as const
export type T_ElementType = (typeof ElementTypes)[keyof typeof ElementTypes]

export class UiElementModel {
  @Expose()
  @Type(() => Number)
  displayId!: number

  @Expose()
  @Type(() => String)
  organizationId!: string

  @Expose()
  @Type(() => Number)
  screenId!: number

  @Expose()
  @Type(() => String)
  type!: T_ElementType

  @Expose()
  @Type(() => Number)
  bboxTop!: number

  @Expose()
  @Type(() => Number)
  bboxLeft!: number

  @Expose()
  @Type(() => Number)
  bboxWidth!: number

  @Expose()
  @Type(() => Number)
  bboxHeight!: number

  @Expose()
  @Type(() => UiElementSpecModel)
  specs!: UiElementSpecModel[]
}

export class UiScreenModel {
  @Expose()
  @Type(() => Number)
  displayId!: number

  @Expose()
  @Type(() => String)
  organizationId!: string

  @Expose()
  @Type(() => String)
  specId!: string

  @Expose()
  @Type(() => String)
  image!: string

  @Expose()
  @Type(() => String)
  name!: string

  @Expose()
  @Type(() => String)
  description!: string | null

  @Expose()
  @Type(() => Boolean)
  checked!: boolean

  @Expose()
  @Type(() => UiElementModel)
  elements!: UiElementModel[]
}

export class UiSpecDocumentModel extends BaseDocumentModel<typeof DocumentTypes.UI_SPEC> {
  @Expose()
  @Type(() => Boolean)
  isFigma!: boolean

  @Expose()
  @Type(() => UiScreenModel)
  screens!: UiScreenModel[]
}

export type T_DocumentModel<T extends T_DocumentType = any> =
  T extends typeof DocumentTypes.MAIN_DOCUMENT
    ? MainDocumentModel
    : T extends typeof DocumentTypes.EPIC
    ? EpicDocumentModel
    : T extends typeof DocumentTypes.USER_STORY
    ? UserStoryDocumentModel
    : T extends typeof DocumentTypes.UI_SPEC
    ? UiSpecDocumentModel
    : never

export const isDocumentType = <T extends T_DocumentType = any>(
  model: T_DocumentModel,
  type: T,
): model is T_DocumentModel<T> => {
  return model.doc.type === type
}

export const mapDocByType = (type?: T_DocumentModel['doc']['type']) => {
  switch (type) {
    case 'MAIN_DOCUMENT': {
      return MainDocumentModel
    }
    case 'USER_STORY': {
      return UserStoryDocumentModel
    }
    case 'EPIC': {
      return EpicDocumentModel
    }
    case 'UI_SPEC': {
      return UiSpecDocumentModel
    }
    default: {
      return BaseDocumentModel
    }
  }
}
