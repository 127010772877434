import { notification, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import React from 'react'
import { QuestionariesList, QuestionaryModel } from '@mg/contracts'
import { format } from 'date-fns'
import { css } from '@emotion/react'
import { isArray, parseError } from '../../utils'
import { questionariesService } from '../../services/api/questionaries.service'

export const Questionaries: React.FC = () => {
  const [notificationApi, contextHolder] = notification.useNotification({ top: 60 })
  const [tableQuery, setTableQuery] = React.useState<{
    page: number
    pageSize: number
    order: 'asc' | 'desc'
  }>({ page: 1, pageSize: 25, order: 'desc' })

  const [isLoading, setIsLoading] = React.useState(false)
  const [data, setData] = React.useState<QuestionariesList.Result>({ questionaries: [], count: 0 })

  const loadData = React.useCallback(() => {
    setIsLoading(true)
    questionariesService
      .list({
        page: tableQuery.page,
        take: tableQuery.pageSize,
        order: tableQuery.order,
      })
      .then((res) => setData(res))
      .catch((error) => {
        notificationApi.error({
          message: 'Error',
          description: parseError(error),
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [notificationApi, tableQuery.order, tableQuery.page, tableQuery.pageSize])

  React.useEffect(() => {
    loadData()
  }, [loadData])

  const columns: ColumnsType<QuestionaryModel> = React.useMemo(() => {
    return [
      {
        title: 'ID',
        dataIndex: 'id',
        fixed: 'left',
      },
      {
        title: 'Created At',
        dataIndex: 'createdAt',
        render: (createdAt) => format(new Date(createdAt), 'dd MMM, hh:mm a'),
        sortOrder: tableQuery.order === 'desc' ? 'descend' : 'ascend',
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
      },
      {
        title: 'Question',
        dataIndex: 'code',
        render: (code: string) => {
          if (code === 'request_new_requirement') {
            return 'What documents would you like to have in feature iterations?'
          }

          return code
        },
      },
      {
        title: 'Answer',
        dataIndex: 'answer',
        render: (answer: string) => {
          return (
            <div
              css={css`
                white-space: pre-wrap;
              `}
            >
              {answer}
            </div>
          )
        },
      },
    ]
  }, [tableQuery.order])

  return (
    <>
      {contextHolder}
      <Table
        size="small"
        loading={isLoading}
        pagination={{
          position: ['bottomRight'],
          total: data.count,
          current: tableQuery.page,
          showSizeChanger: true,
          showTotal: (total) => `Total ${total} items`,
          pageSizeOptions: ['25', '50', '100'],
          pageSize: tableQuery.pageSize,
        }}
        onChange={(pagination, filters, sorter) => {
          setTableQuery((prev) => {
            let { order } = prev

            if (sorter && !isArray(sorter) && sorter.field === 'createdAt') {
              order = sorter.order === 'descend' ? 'desc' : 'asc'
            }

            return {
              page: pagination.current || prev.page,
              pageSize: pagination.pageSize || prev.pageSize,
              order,
            }
          })
        }}
        columns={columns}
        dataSource={data.questionaries}
        rowKey="id"
        scroll={{ x: true }}
        tableLayout="fixed"
      />
    </>
  )
}
