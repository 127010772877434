import { GlobalStyle } from './components/GlobalStyle'
import { Router } from './navigation'
import { ThemeConfigProvider } from './providers/theme'

export const App = () => {
  return (
    <ThemeConfigProvider
      theme={{
        token: {
          colorPrimary: '#1C0DFF',
          colorBgLayout: '#F0F2F5',
          borderRadius: 8,
          fontFamily:
            "Inter,-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji'",
        },
        components: {
          Alert: {
            colorWarningBg: '#feffe6',
            colorWarningBorder: '#ffd666',
          },
          Avatar: {
            colorTextPlaceholder: '#bfbfbf',
            borderRadius: 2,
          },
          Layout: {
            controlHeight: 26,
          },
          Input: {
            borderRadius: 2,
            controlHeight: 36,
          },
          Select: {
            borderRadius: 2,
            controlHeight: 36,
          },
          Button: {
            controlHeightSM: 32,
            controlHeight: 40,
            controlHeightLG: 48,
            fontSize: 15,
          },
        },
      }}
    >
      <GlobalStyle />
      <Router />
    </ThemeConfigProvider>
  )
}
