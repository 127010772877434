import { Avatar, Checkbox, Form, Input, Modal, notification, Space, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import React from 'react'
import { UserOutlined } from '@ant-design/icons'
import { AdminUserList, AdminUserAccountModel } from '@mg/contracts'
import { format } from 'date-fns'
import { debounce } from 'lodash'
import { userAccountService } from '../../services/api'
import { getCdnUrl, isArray, parseError } from '../../utils'
import { CustomButton } from '../../components/CustomButton'

export const UserAccounts: React.FC = () => {
  const [notificationApi, contextHolder] = notification.useNotification({ top: 60 })
  const [tableQuery, setTableQuery] = React.useState<{
    page: number
    pageSize: number
    order: 'asc' | 'desc'
  }>({ page: 1, pageSize: 25, order: 'desc' })

  const [isLoading, setIsLoading] = React.useState(false)
  const [data, setData] = React.useState<AdminUserList.Result>({ accounts: [], count: 0 })

  const [search, setSearch] = React.useState('')
  const [hideWithoutDocs, setHideWithoutDocs] = React.useState(false)
  const handleSearch = React.useMemo(() => {
    return debounce((value: string) => {
      setSearch(value)
    }, 1000)
  }, [])

  const loadData = React.useCallback(() => {
    setIsLoading(true)
    userAccountService
      .list({
        page: tableQuery.page,
        take: tableQuery.pageSize,
        order: tableQuery.order,
        search: search || undefined,
        hideWithoutDocs,
      })
      .then((res) => setData(res))
      .catch((error) => {
        notificationApi.error({
          message: 'Error',
          description: parseError(error),
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [
    tableQuery.page,
    tableQuery.pageSize,
    tableQuery.order,
    search,
    hideWithoutDocs,
    notificationApi,
  ])

  React.useEffect(() => {
    loadData()
  }, [loadData])

  const [updateModalOpen, setUpdateModalOpen] = React.useState<AdminUserAccountModel>()
  const [isUpdateLoading, setIsUpdateLoading] = React.useState(false)
  const [updateForm] = Form.useForm()
  const onCancelModal = React.useCallback(() => {
    setUpdateModalOpen(undefined)
    setIsUpdateLoading(false)
    updateForm.resetFields()
  }, [updateForm])
  const onFinishUpdate = React.useCallback(
    (values: { id: number; email: string; isActive: boolean; isVerified: boolean }) => {
      if (!updateModalOpen) return
      setIsUpdateLoading(true)
      userAccountService
        .update({ id: updateModalOpen.id }, values)
        .then(() => {
          loadData()
          onCancelModal()
        })
        .catch((error) => {
          notificationApi.error({
            message: 'Error',
            description: parseError(error),
          })
        })
        .finally(() => {
          setIsUpdateLoading(false)
        })
    },
    [loadData, notificationApi, onCancelModal, updateModalOpen],
  )

  const columns: ColumnsType<AdminUserAccountModel> = React.useMemo(() => {
    return [
      {
        title: 'ID',
        dataIndex: 'id',
        fixed: 'left',
      },
      {
        title: 'Created At',
        dataIndex: 'createdAt',
        render: (createdAt) => format(new Date(createdAt), 'dd MMM, hh:mm a'),
        sortOrder: tableQuery.order === 'desc' ? 'descend' : 'ascend',
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
      },
      {
        title: 'Updated At',
        dataIndex: 'updatedAt',
        render: (updatedAt) => format(new Date(updatedAt), 'dd MMM, hh:mm a'),
      },
      {
        title: 'Email',
        dataIndex: 'email',
      },
      {
        title: 'First Name',
        dataIndex: 'firstName',
      },
      {
        title: 'Last Name',
        dataIndex: 'lastName',
      },
      {
        title: 'Avatar',
        dataIndex: 'avatar',
        render: (avatar) => <Avatar size={40} icon={<UserOutlined />} src={getCdnUrl(avatar)} />,
      },
      {
        title: 'Google ID',
        dataIndex: 'googleId',
      },
      {
        title: 'LinkedIn ID',
        dataIndex: 'linkedinId',
      },
      {
        title: 'Is Verified',
        dataIndex: 'isVerified',
        render: (isVerified) => (isVerified ? 'Yes' : 'No'),
      },
      {
        title: 'Is Active',
        dataIndex: 'isActive',
        render: (isVerified) => (isVerified ? 'Yes' : 'No'),
      },
      {
        title: 'Position',
        dataIndex: 'position',
      },
      {
        title: 'Tech Level',
        dataIndex: 'techLevel',
      },
      {
        title: 'Country',
        dataIndex: 'country',
      },
      {
        title: 'City',
        dataIndex: 'city',
      },
      {
        title: 'Organizations',
        dataIndex: 'organizationsCount',
      },
      {
        title: 'projects',
        dataIndex: 'projectsCount',
      },
      {
        title: 'Documents',
        dataIndex: ['counts', 'documents'],
      },
      {
        title: 'Requirements',
        dataIndex: ['counts', 'requirements'],
      },
      {
        title: 'Specifications',
        dataIndex: ['counts', 'specifications'],
      },
      {
        key: 'actions',
        fixed: 'right',
        render: (_, record) => (
          <CustomButton
            size="small"
            type="primary"
            ghost
            onClick={() => {
              setUpdateModalOpen(record)
              updateForm.setFieldsValue(record)
            }}
          >
            Edit
          </CustomButton>
        ),
      },
    ]
  }, [tableQuery.order, updateForm])

  return (
    <>
      {contextHolder}
      <Modal
        centered
        title="Update User Account"
        open={!!updateModalOpen}
        confirmLoading={isUpdateLoading}
        cancelButtonProps={{
          type: 'primary',
          ghost: true,
          size: 'small',
        }}
        okButtonProps={{
          size: 'small',
        }}
        okText="Save"
        cancelText="Cancel"
        onOk={updateForm?.submit}
        onCancel={onCancelModal}
      >
        <Form name="update-user" layout="vertical" form={updateForm} onFinish={onFinishUpdate}>
          <Form.Item name="id" label="ID">
            <Input disabled />
          </Form.Item>
          <Form.Item name="email" label="Email">
            <Input disabled />
          </Form.Item>
          <Form.Item name="isVerified" valuePropName="checked" noStyle>
            <Checkbox>Is Verified</Checkbox>
          </Form.Item>
          <Form.Item name="isActive" valuePropName="checked" noStyle>
            <Checkbox>Is Active</Checkbox>
          </Form.Item>
        </Form>
      </Modal>
      <Space>
        <Input placeholder="Search" allowClear onChange={(e) => handleSearch(e.target.value)} />
        <Checkbox checked={hideWithoutDocs} onChange={(e) => setHideWithoutDocs(e.target.checked)}>
          Hide users without created resources.
        </Checkbox>
      </Space>
      <br />
      <Table
        size="small"
        loading={isLoading}
        pagination={{
          position: ['bottomRight'],
          total: data.count,
          current: tableQuery.page,
          showSizeChanger: true,
          showTotal: (total) => `Total ${total} items`,
          pageSizeOptions: ['25', '50', '100'],
          pageSize: tableQuery.pageSize,
        }}
        onChange={(pagination, filters, sorter) => {
          setTableQuery((prev) => {
            let { order } = prev

            if (sorter && !isArray(sorter) && sorter.field === 'createdAt') {
              order = sorter.order === 'descend' ? 'desc' : 'asc'
            }

            return {
              page: pagination.current || prev.page,
              pageSize: pagination.pageSize || prev.pageSize,
              order,
            }
          })
        }}
        columns={columns}
        dataSource={data.accounts}
        rowKey="id"
        scroll={{ x: true }}
        tableLayout="fixed"
      />
    </>
  )
}
