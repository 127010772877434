import { Expose, Type } from 'class-transformer'

export class DocCountsModel {
  @Expose()
  @Type(() => Number)
  documents!: number

  @Expose()
  @Type(() => Number)
  requirements!: number

  @Expose()
  @Type(() => Number)
  specifications!: number
}
