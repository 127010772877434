import { Expose, Type } from 'class-transformer'

export class LabelModel {
  @Expose()
  @Type(() => Number)
  id!: number

  @Expose()
  @Type(() => Date)
  createdAt!: Date

  @Expose()
  @Type(() => String)
  title!: string
}
