import { Spin, SpinProps } from 'antd'
import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import classNames from 'classnames'

export const CustomSpin = styled<React.FC<SpinProps & { opaque?: boolean }>>(
  ({ opaque, children, className, wrapperClassName, ...props }) => {
    return (
      <Spin {...props} wrapperClassName={classNames(className, wrapperClassName)}>
        {children}
      </Spin>
    )
  },
)`
  display: flex;
  flex: auto;
  flex-direction: column;
  > .ant-spin-container {
    flex: auto;
    display: flex;
    flex-direction: column;
  }
  ${({ opaque }) => {
    return (
      opaque &&
      css`
        > .ant-spin-blur {
          opacity: 0;
          transition: unset;
        }
      `
    )
  }}
`
