import React from 'react'
import { Layout } from 'antd'
import { css } from '@emotion/react'
import { observer } from 'mobx-react-lite'
import { Outlet } from 'react-router-dom'
import { CustomSpin } from '../CustomSpin'
import { PageHeader } from './PageHeader'
import { identityStore } from '../../providers/store'

const _PageLayout: React.FC = () => {
  return (
    <CustomSpin
      size="large"
      spinning={!identityStore.isInitialized}
      opaque
      css={css({ minHeight: '100%' })}
    >
      <Layout css={css({ minHeight: '100%' })}>
        <PageHeader />
        <Layout.Content
          css={css`
            display: flex;
            flex-direction: column;
            padding: 25px;
            overflow-y: auto;
            height: 1px;
          `}
        >
          <Outlet />
        </Layout.Content>
      </Layout>
    </CustomSpin>
  )
}

export const PageLayout = observer(_PageLayout)
