import { Expose, Type } from 'class-transformer'
import {
  IsBoolean,
  IsDate,
  IsEmail,
  IsEnum,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
} from 'class-validator'
import { DocCountsModel } from './doc-counts.model'

export const TechLevels = {
  BASIC: 'basic',
  INTERMEDIATE: 'intermediate',
  EXPERT: 'expert',
} as const

export type T_TechLevel = (typeof TechLevels)[keyof typeof TechLevels]

export class UserAccountModel {
  @Expose()
  @IsNotEmpty()
  @IsNumber()
  @Type(() => Number)
  id!: number

  @Expose()
  @IsNotEmpty()
  @IsDate()
  @Type(() => Date)
  createdAt!: Date

  @Expose()
  @IsNotEmpty()
  @IsDate()
  @Type(() => Date)
  updatedAt!: Date

  @Expose()
  @IsOptional()
  @IsString()
  @Type(() => String)
  avatar!: string | null

  @Expose()
  @IsNotEmpty()
  @IsEmail()
  @Type(() => String)
  email!: string

  @Expose()
  @IsOptional()
  @IsString()
  @Type(() => String)
  googleId!: string | null

  @Expose()
  @IsOptional()
  @IsString()
  @Type(() => String)
  linkedinId!: string | null

  @Expose()
  @IsNotEmpty()
  @IsBoolean()
  @Type(() => Boolean)
  isVerified!: boolean

  @Expose()
  @IsNotEmpty()
  @IsBoolean()
  @Type(() => Boolean)
  isActive!: boolean

  @Expose()
  @IsNotEmpty()
  @IsString()
  @Type(() => String)
  firstName!: string

  @Expose()
  @IsNotEmpty()
  @IsString()
  @Type(() => String)
  lastName!: string

  @Expose()
  @IsOptional()
  @IsString()
  @Type(() => String)
  position!: string | null

  @Expose()
  @IsNotEmpty()
  @IsEnum(TechLevels)
  @Type(() => String)
  techLevel!: string | null

  @Expose()
  @IsNotEmpty()
  @IsString()
  @Type(() => String)
  country!: string

  @Expose()
  @IsOptional()
  @IsString()
  @Type(() => String)
  city!: string | null
}

export class AdminUserAccountModel {
  @Expose()
  @IsNotEmpty()
  @IsNumber()
  @Type(() => Number)
  id!: number

  @Expose()
  @IsNotEmpty()
  @IsDate()
  @Type(() => Date)
  createdAt!: Date

  @Expose()
  @IsNotEmpty()
  @IsDate()
  @Type(() => Date)
  updatedAt!: Date

  @Expose()
  @IsOptional()
  @IsString()
  @Type(() => String)
  avatar!: string | null

  @Expose()
  @IsNotEmpty()
  @IsEmail()
  @Type(() => String)
  email!: string

  @Expose()
  @IsOptional()
  @IsString()
  @Type(() => String)
  googleId!: string | null

  @Expose()
  @IsOptional()
  @IsString()
  @Type(() => String)
  linkedinId!: string | null

  @Expose()
  @IsNotEmpty()
  @IsBoolean()
  @Type(() => Boolean)
  isVerified!: boolean

  @Expose()
  @IsNotEmpty()
  @IsBoolean()
  @Type(() => Boolean)
  isActive!: boolean

  @Expose()
  @IsNotEmpty()
  @IsString()
  @Type(() => String)
  firstName!: string

  @Expose()
  @IsNotEmpty()
  @IsString()
  @Type(() => String)
  lastName!: string

  @Expose()
  @IsOptional()
  @IsString()
  @Type(() => String)
  position!: string | null

  @Expose()
  @IsNotEmpty()
  @IsEnum(TechLevels)
  @Type(() => String)
  techLevel!: string | null

  @Expose()
  @IsNotEmpty()
  @IsString()
  @Type(() => String)
  country!: string

  @Expose()
  @IsOptional()
  @IsString()
  @Type(() => String)
  city!: string | null

  @Expose()
  @Type(() => Number)
  organizationsCount!: number

  @Expose()
  @Type(() => Number)
  projectsCount!: number

  @Expose()
  @Type(() => DocCountsModel)
  counts!: DocCountsModel
}

export class PublicAccountModel {
  @Expose()
  @IsNotEmpty()
  @IsNumber()
  @Type(() => Number)
  id!: number

  @Expose()
  @IsOptional()
  @IsString()
  @Type(() => String)
  avatar!: string | null

  @Expose()
  @IsNotEmpty()
  @IsString()
  @Type(() => String)
  firstName!: string

  @Expose()
  @IsNotEmpty()
  @IsString()
  @Type(() => String)
  lastName!: string
}
