import { envConfig } from '../configs'

export const getBase64 = async (file: Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = (error) => reject(error)
  })

export const getCdnUrl = (url?: string | null) => {
  if (!url) {
    return undefined
  }

  if (url.startsWith('http') || url.startsWith('/')) {
    return url
  }

  return `${envConfig.FILES_URL}${url}`
}
