import React from 'react'
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { publicRoutes } from './public'
import { PageLayout } from '../components/PageLayout'
import { identityStore } from '../providers/store'
import { privateRoutes } from './private'
import { HomePage } from '../pages/Home'
import { UserAccountsPage } from '../pages/UserAccounts'
import { QuestionariesPage } from '../pages/Questionaries'
import { DocumentTemplatesPage } from '../pages/DocumentTemplates'
import { ElementsOfDocumentTemplatePage } from '../pages/ElementsOfDocumentTemplate'

export const publicRouter = createBrowserRouter([
  {
    path: publicRoutes.oauth.path,
    element: <> </>,
  },
  {
    element: <PageLayout />,
    children: [{ path: '*', element: <HomePage /> }],
  },
])
export const privateRouter = createBrowserRouter([
  {
    element: <PageLayout />,
    children: [
      { id: 'accounts', path: privateRoutes.userAccounts.path, element: <UserAccountsPage /> },
      {
        id: 'document_templates',
        path: privateRoutes.documentTemplates.path,
        element: <DocumentTemplatesPage />,
      },
      {
        id: 'elements_of_document_templates',
        path: privateRoutes.elementsOfDocumentTemplate.path,
        element: <ElementsOfDocumentTemplatePage />,
      },
      {
        id: 'questionaries',
        path: privateRoutes.questionaries.path,
        element: <QuestionariesPage />,
      },
    ],
  },
  { path: '*', element: <Navigate to={privateRoutes.userAccounts.path} replace /> },
])

const _Router: React.FC = () => {
  const router = identityStore.isLoggedIn ? privateRouter : publicRouter

  if (!identityStore.isInitialized) return null

  return <RouterProvider router={router} />
}

export const Router = observer(_Router)
