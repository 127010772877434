import { AdminOauth, AuthOauthUrl } from '@mg/contracts'
import { api } from './api'

export const authService = {
  async getOauthUrl(body: AuthOauthUrl.Body) {
    const res = await api.post<AuthOauthUrl.Result>('/auth/oauth-url', body)

    return res.data
  },
  async oauthLogin(body: AdminOauth.Body) {
    const res = await api.post<AdminOauth.Result>('/auth/admin-oauth', body)

    return res.data
  },
}
