import React from 'react'
import { Link } from 'react-router-dom'
import { css } from '@emotion/react'

interface I_Props {
  to: string
  icon?: React.ReactNode
  children?: React.ReactNode
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
  className?: string
}

export const MenuLink: React.FC<I_Props> = ({
  to,
  icon = null,
  children = null,
  onClick = undefined,
  className = undefined,
}: I_Props) => {
  return (
    <Link
      to={to}
      className={className}
      onClick={onClick}
      css={css`
        color: inherit;
        :hover {
          color: inherit;
        }
        :active {
          color: inherit;
        }
      `}
    >
      {icon}
      <span className="ant-menu-title-content">{children}</span>
    </Link>
  )
}
