import { css, Global } from '@emotion/react'
import React from 'react'
import { theme } from 'antd'

export const GlobalStyle = () => {
  const { token } = theme.useToken()

  return (
    <Global
      styles={css`
        @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;700&display=swap');
        @font-face {
          font-family: 'e-Ukraine Head';
          src:
            local('e-Ukraine Head Bold'),
            local('e-UkraineHead-Bold'),
            url('/fonts/e-UkraineHead-Bold.woff2') format('woff2'),
            url('/fonts/e-UkraineHead-Bold.woff') format('woff');
          font-weight: bold;
          font-style: normal;
          font-display: swap;
        }
        @font-face {
          font-family: 'e-Ukraine Head';
          src:
            local('e-Ukraine Head Regular'),
            local('e-UkraineHead-Regular'),
            url('/fonts/e-UkraineHead-Regular.woff2') format('woff2'),
            url('/fonts/e-UkraineHead-Regular.woff') format('woff');
          font-weight: normal;
          font-style: normal;
          font-display: swap;
        }
        @font-face {
          font-family: 'e-Ukraine Head';
          src:
            local('e-Ukraine Head Medium'),
            local('e-UkraineHead-Medium'),
            url('/fonts/e-UkraineHead-Medium.woff2') format('woff2'),
            url('/fonts/e-UkraineHead-Medium.woff') format('woff');
          font-weight: 500;
          font-style: normal;
          font-display: swap;
        }
        @font-face {
          font-family: 'e-Ukraine Head';
          src:
            local('e-Ukraine Head Light'),
            local('e-UkraineHead-Light'),
            url('/fonts/e-UkraineHead-Light.woff2') format('woff2'),
            url('/fonts/e-UkraineHead-Light.woff') format('woff');
          font-weight: 300;
          font-style: normal;
          font-display: swap;
        }
        @font-face {
          font-family: 'e-Ukraine Head';
          src:
            local('e-Ukraine Head UltraLight'),
            local('e-UkraineHead-UltraLight'),
            url('/fonts/e-UkraineHead-UltraLight.woff2') format('woff2'),
            url('/fonts/e-UkraineHead-UltraLight.woff') format('woff');
          font-weight: 200;
          font-style: normal;
          font-display: swap;
        }
        @font-face {
          font-family: 'e-Ukraine Head';
          src:
            local('e-Ukraine Head Thin'),
            local('e-UkraineHead-Thin'),
            url('/fonts/e-UkraineHead-Thin.woff2') format('woff2'),
            url('/fonts/e-UkraineHead-Thin.woff') format('woff');
          font-weight: 100;
          font-style: normal;
          font-display: swap;
        }
        h1,
        h2,
        h3,
        h4,
        h5 {
          &.ant-typography {
            margin-bottom: 0;
            font-family:
              'e-Ukraine Head',
              -apple-system,
              BlinkMacSystemFont,
              'Segoe UI',
              Roboto,
              'Helvetica Neue',
              Arial,
              'Noto Sans',
              sans-serif,
              'Apple Color Emoji',
              'Segoe UI Emoji',
              'Segoe UI Symbol',
              'Noto Color Emoji';
          }
        }

        body {
          background: ${token.colorBgLayout};
        }
        #root {
          height: 100%;
        }
        .ant-menu-light {
          &:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):hover,
          & > .ant-menu:not(.ant-menu-horizontal) {
            .ant-menu-item:not(.ant-menu-item-selected):hover {
              background-color: #f0f5ff;
              color: ${token.colorText};
            }
          }
        }
        .ant-modal-content {
          margin: 20px;
        }

        *::-webkit-scrollbar {
          background: transparent;
          width: 12px;
        }

        *::-webkit-scrollbar-track {
          border-radius: 8px;
        }

        *::-webkit-scrollbar-thumb {
          height: 56px;
          border-radius: 8px;
          border: 4px solid transparent;
          background-clip: content-box;
          background-color: transparent;
        }

        *:hover::-webkit-scrollbar-thumb {
          background-color: #909090;
        }
        *::-webkit-scrollbar-thumb:hover {
          background-color: #555555;
        }
      `}
    />
  )
}
