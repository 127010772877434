import { Expose, Type } from 'class-transformer'

export class FileModel {
  @Expose()
  @Type(() => Number)
  id!: number

  @Expose()
  @Type(() => Date)
  createdAt!: Date

  @Expose()
  @Type(() => String)
  key!: string

  @Expose()
  @Type(() => String)
  name!: string

  @Expose()
  @Type(() => String)
  mimeType!: string
}
