import { useCallback, useEffect, useRef, useState } from 'react'

interface I_PopupWindow {
  url: string
  onSuccess: (params: Record<string, string>) => void
  param?: string
  width?: number
  height?: number
}

export const usePopupWindow = (name: string) => {
  const [externalWindow, setExternalWindow] = useState<Window | null>(null)
  const intervalRef = useRef<number | null>(null)
  const paramRef = useRef<I_PopupWindow>()

  const openPopup = useCallback(
    ({ url, height = 500, width = 500, ...rest }: I_PopupWindow) => {
      if (
        paramRef.current &&
        (paramRef.current.height !== height || paramRef.current.height !== height)
      ) {
        externalWindow?.close()
      }

      const left = window.screenX + (window.outerWidth - width) / 2
      const top = window.screenY + (window.outerHeight - height) / 2.5

      paramRef.current = {
        url,
        height,
        width,
        ...rest,
      }
      setExternalWindow(
        window.open(
          url,
          name,
          `menubar=no,location=no,status=no,opener,width=${width},height=${height},left=${left},top=${top}`,
        ),
      )
    },
    [externalWindow, name],
  )

  const clearTimer = useCallback(() => {
    if (intervalRef.current) {
      window.clearInterval(intervalRef.current)
      intervalRef.current = null
    }
  }, [])

  useEffect(() => {
    if (externalWindow) {
      intervalRef.current = window.setInterval(() => {
        try {
          const params = new URL(externalWindow.location.href).searchParams
          const paramValue = params.get(paramRef.current?.param || 'code')

          if (paramValue) {
            paramRef.current?.onSuccess(Object.fromEntries(params.entries()))
            clearTimer()
            externalWindow.close()
            setExternalWindow(null)
          }
        } catch (error) {
          // ignore
        } finally {
          if (!externalWindow || externalWindow.closed) {
            clearTimer()
          }
        }
      }, 500)
    }

    return () => {
      if (externalWindow) {
        externalWindow.close()
      }

      clearTimer()
    }
  }, [externalWindow, clearTimer])

  return openPopup
}
