/* eslint-disable react/require-default-props */
import styled from '@emotion/styled'
import { Button } from 'antd'
import { ButtonProps } from 'antd/es/button/button'
import React from 'react'
import classNames from 'classnames'

type T_ButtonType = ButtonProps['type'] | 'secondary'
type T_Props = Omit<ButtonProps, 'type'> & {
  type?: T_ButtonType
}

const _Button = React.forwardRef<HTMLElement, T_Props>(
  ({ type = undefined, className, ...rest }, ref) => {
    return (
      <Button
        {...rest}
        ref={ref}
        type={type === 'secondary' ? 'primary' : type}
        className={classNames(className, {
          'ant-btn-secondary': type === 'secondary',
        })}
      />
    )
  },
)

export const CustomButton = styled(_Button)`
  &.ant-btn-secondary {
    background: #1c0dff14;
    color: #1c0dff;
    &:not(:disabled):hover {
      background: #1c0dff26;
      color: #1c0dff;
    }
    :active {
      background: #1c0dff;
      color: #ffffff;
    }
    &.ant-btn-background-ghost {
      background: transparent;
      border-color: transparent;
      &:not(:disabled):hover {
        background: #f0f5ff;
        border-color: #f0f5ff;
      }
    }
  }
` as unknown as React.ForwardRefExoticComponent<T_Props & React.RefAttributes<HTMLElement>>
