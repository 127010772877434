import { QuestionariesList } from '@mg/contracts'
import qs from 'qs'
import { api } from './api'

export const questionariesService = {
  async list(query?: QuestionariesList.Query) {
    let url = '/admin/questionaries'

    if (query) {
      url = `${url}?${qs.stringify(query)}`
    }

    const res = await api.get<QuestionariesList.Result>(url)

    return res.data
  },
}
