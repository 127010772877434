import { AxiosError } from 'axios'

export const parseError = (e: unknown) => {
  if (e instanceof AxiosError && e.isAxiosError) {
    if (e.code === 'ERR_NETWORK' || (e.status && e.status >= 500)) {
      return 'An error occurred on our end. Refresh the page and try again.'
    }

    if (e.response?.data?.message) {
      return e.response.data.message as string
    }
  }

  if (e instanceof Error) {
    return e.message
  }

  if (typeof e === 'string') {
    return e
  }

  return 'Something went wrong'
}
