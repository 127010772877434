import { Expose, Type } from 'class-transformer'
import { DocCountsModel } from './doc-counts.model'

export const PlatformType = {
  DESKTOP: 'DESKTOP',
  WEB: 'WEB',
  MOBILE_MULTIPLATFORM: 'MOBILE_MULTIPLATFORM',
  IOS: 'IOS',
  ANDROID: 'ANDROID',
  OTHERS: 'OTHERS',
} as const

export type T_PlatformType = (typeof PlatformType)[keyof typeof PlatformType]

export const Domains = {
  PRODUCTIVITY_AND_COLLABORATION: 'PRODUCTIVITY_AND_COLLABORATION',
  SOCIAL_NETWORKING: 'SOCIAL_NETWORKING',
  ECOMMERCE_AND_RETAIL: 'ECOMMERCE_AND_RETAIL',
  ENTERTAINMENT_AND_MEDIA: 'ENTERTAINMENT_AND_MEDIA',
  EDUCATION_AND_LEARNING: 'EDUCATION_AND_LEARNING',
  HEALTH_AND_FITNESS: 'HEALTH_AND_FITNESS',
  TRAVEL_AND_TRANSPORTATION: 'TRAVEL_AND_TRANSPORTATION',
  FINANCE_AND_BANKING: 'FINANCE_AND_BANKING',
  UTILITIES_AND_TOOLS: 'UTILITIES_AND_TOOLS',
  GAMING: 'GAMING',
  OTHERS: 'OTHERS',
} as const

export type T_Domain = (typeof Domains)[keyof typeof Domains]

export class ProjectModel {
  @Expose()
  @Type(() => String)
  id!: string

  @Expose()
  @Type(() => Date)
  createdAt!: Date

  @Expose()
  @Type(() => Date)
  updatedAt!: Date

  @Expose()
  @Type(() => String)
  organizationId!: string

  @Expose()
  @Type(() => String)
  avatar!: string | null

  @Expose()
  @Type(() => String)
  name!: string

  @Expose()
  @Type(() => String)
  description!: string

  @Expose()
  @Type(() => String)
  slug!: string

  @Expose()
  @Type(() => Boolean)
  pinned!: boolean

  @Expose()
  @Type(() => String)
  type!: T_PlatformType

  @Expose()
  @Type(() => String)
  domain!: T_Domain

  @Expose()
  @Type(() => DocCountsModel)
  counts!: DocCountsModel
}
