import { autorun, makeAutoObservable } from 'mobx'
import { AdminOauth } from '@mg/contracts'
import { api, authService, setToken } from '../../services/api'
import { cacheService } from '../../services'
import { T_Nullable, T_WritablePart } from './types'

const ACCESS_TOKEN_KEY = 'accessToken'

class IdentityStore {
  isInitialized: boolean = false

  accessToken: T_Nullable<string> = null

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })

    const token = cacheService.get<string>(ACCESS_TOKEN_KEY)

    if (token) {
      setToken(token)
      this.setData({ isInitialized: true, accessToken: token })
    } else {
      this.setData({ isInitialized: true })
    }

    autorun(() => {
      setToken(this.accessToken)
    })

    api.interceptors.response.use(
      (response) => {
        return response
      },
      async (error) => {
        if (error.response?.status === 401) {
          this.logout()

          return Promise.reject()
        }

        return Promise.reject(error)
      },
    )
  }

  private setData(data: Partial<T_WritablePart<IdentityStore>>) {
    if ('accessToken' in data) {
      cacheService.set(ACCESS_TOKEN_KEY, data.accessToken)
    }

    Object.assign(this, data)
  }

  get isLoggedIn() {
    return this.isInitialized && !!this.accessToken
  }

  async login(data: AdminOauth.Body) {
    const res = await authService.oauthLogin(data)

    this.setData({ accessToken: res.token })

    return this
  }

  logout() {
    cacheService.delete(ACCESS_TOKEN_KEY)
    this.setData({ accessToken: null })
  }
}

export const identityStore = new IdentityStore()
