const API_URL = process.env['REACT_APP_API_URL']
const PROXY_URL = process.env['REACT_APP_PROXY_URL']
const FILES_URL = process.env['REACT_APP_FILES_URL']
const PRIVACY_URL = process.env['REACT_APP_PRIVACY_URL']
const TERMS_URL = process.env['REACT_APP_TERMS_URL']

export const envConfig = {
  API_URL: !API_URL || PROXY_URL ? '/api' : API_URL,
  FILES_URL: !FILES_URL ? '/' : FILES_URL,
  PRIVACY_URL: !PRIVACY_URL ? 'https://www.mindygem.com/privacy.pdf' : PRIVACY_URL,
  TERMS_URL: !TERMS_URL ? 'https://www.mindygem.com/terms.pdf' : TERMS_URL,
}
