import React, { ReactNode, ComponentProps, useMemo } from 'react'
import { ConfigProvider, GlobalToken, theme } from 'antd'
import { css, Global, Theme, ThemeProvider } from '@emotion/react'

interface I_Props extends ComponentProps<typeof ConfigProvider> {
  children: ReactNode
}

declare module '@emotion/react' {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  export interface Theme {
    token: GlobalToken
    media: {
      xs: string
      sm: string
      md: string
      lg: string
      xl: string
      xxl: string
    }
  }
}

const EmotionThemeProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { token } = theme.useToken()

  const themeData: Theme = useMemo(
    () => ({
      token,
      media: {
        xs: `@media (max-width: ${token.screenXSMax}px)`,
        sm: `@media (min-width: ${token.screenSMMin}px)`,
        md: `@media (min-width: ${token.screenMDMin}px)`,
        lg: `@media (min-width: ${token.screenLGMin}px)`,
        xl: `@media (min-width: ${token.screenXLMin}px)`,
        xxl: `@media (min-width: ${token.screenXXLMin}px)`,
      },
    }),
    [token],
  )

  return (
    <ThemeProvider theme={themeData}>
      <Global
        styles={css`
          @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;700&display=swap');
          @font-face {
            font-family: 'e-Ukraine Head';
            src:
              local('e-Ukraine Head Bold'),
              local('e-UkraineHead-Bold'),
              url('/fonts/e-UkraineHead-Bold.woff2') format('woff2'),
              url('/fonts/e-UkraineHead-Bold.woff') format('woff');
            font-weight: bold;
            font-style: normal;
            font-display: swap;
          }
          @font-face {
            font-family: 'e-Ukraine Head';
            src:
              local('e-Ukraine Head Regular'),
              local('e-UkraineHead-Regular'),
              url('/fonts/e-UkraineHead-Regular.woff2') format('woff2'),
              url('/fonts/e-UkraineHead-Regular.woff') format('woff');
            font-weight: normal;
            font-style: normal;
            font-display: swap;
          }
          @font-face {
            font-family: 'e-Ukraine Head';
            src:
              local('e-Ukraine Head Medium'),
              local('e-UkraineHead-Medium'),
              url('/fonts/e-UkraineHead-Medium.woff2') format('woff2'),
              url('/fonts/e-UkraineHead-Medium.woff') format('woff');
            font-weight: 500;
            font-style: normal;
            font-display: swap;
          }
          @font-face {
            font-family: 'e-Ukraine Head';
            src:
              local('e-Ukraine Head Light'),
              local('e-UkraineHead-Light'),
              url('/fonts/e-UkraineHead-Light.woff2') format('woff2'),
              url('/fonts/e-UkraineHead-Light.woff') format('woff');
            font-weight: 300;
            font-style: normal;
            font-display: swap;
          }
          @font-face {
            font-family: 'e-Ukraine Head';
            src:
              local('e-Ukraine Head UltraLight'),
              local('e-UkraineHead-UltraLight'),
              url('/fonts/e-UkraineHead-UltraLight.woff2') format('woff2'),
              url('/fonts/e-UkraineHead-UltraLight.woff') format('woff');
            font-weight: 200;
            font-style: normal;
            font-display: swap;
          }
          @font-face {
            font-family: 'e-Ukraine Head';
            src:
              local('e-Ukraine Head Thin'),
              local('e-UkraineHead-Thin'),
              url('/fonts/e-UkraineHead-Thin.woff2') format('woff2'),
              url('/fonts/e-UkraineHead-Thin.woff') format('woff');
            font-weight: 100;
            font-style: normal;
            font-display: swap;
          }
          h1,
          h2,
          h3,
          h4,
          h5 {
            &.ant-typography {
              font-family:
                'e-Ukraine Head',
                -apple-system,
                BlinkMacSystemFont,
                'Segoe UI',
                Roboto,
                'Helvetica Neue',
                Arial,
                'Noto Sans',
                sans-serif,
                'Apple Color Emoji',
                'Segoe UI Emoji',
                'Segoe UI Symbol',
                'Noto Color Emoji';
            }
          }

          body {
            background: ${token.colorBgLayout};
          }
        `}
      />
      {children}
    </ThemeProvider>
  )
}

export const ThemeConfigProvider: React.FC<I_Props> = ({ children, ...props }) => {
  return (
    <ConfigProvider {...props}>
      <EmotionThemeProvider>{children}</EmotionThemeProvider>
    </ConfigProvider>
  )
}
